<template>
    <div class="details">
        <div class="navbar">
            <div @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
            </div>
            <div>推广收益</div>
            <div class="rightItem"></div>
        </div>
        <van-grid class="promoteInfo mt10" column-num="2" :border="false">
            <van-grid-item use-slot>
                <div class="promoteInfo_title">总推广人数</div>
                <div class="promoteInfo_num">{{totalInvites | watchCount}}</div>
            </van-grid-item>
            <van-grid-item use-slot>
                <div class="promoteInfo_title">总推广收益</div>
                <div class="promoteInfo_num">
                    <svg-icon class="goldSvg" iconClass="gold"></svg-icon>
                    <span>{{totalInviteAmount || '0'}}</span>
                </div>
            </van-grid-item>
        </van-grid>
        <div class="splitLine"></div>
        <van-grid class="promoteInfo mt10" column-num="2" :border="false">
            <van-grid-item use-slot>
                <div class="promoteInfo_title">今日推广人数</div>
                <div class="promoteInfo_num">{{todayInvites | watchCount}}</div>
            </van-grid-item>
            <van-grid-item use-slot>
                <div class="promoteInfo_title">今日推广收益</div>
                <div class="promoteInfo_num">
                    <svg-icon class="goldSvg" iconClass="gold"></svg-icon>
                    <span>{{todayInviteAmount || 0 }}</span>
                </div>
            </van-grid-item>
        </van-grid>
        <div class="splitLine"></div>

        <div class="detailList_title">详情列表</div>
        <div class="listBox">
            <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
                :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
                <div class="incomeInfo" v-for="(item, index) in inviteList" :key="index">
                    <div class="title">{{item.userName}}</div>
                    <div class="amountBox">
                        <div class="type">收入</div>
                        <div class="amount">+{{item.incomeAmount || 0}}金币</div>
                    </div>
                    <div class="timer">{{item.rechargeAt | time}}</div>
                </div>
            </Pullrefresh>
        </div>
    </div>
</template>

<script>
    import {
        queryAppInvite,
        queryGameInvite
    } from '@/api/mine/promote.js'
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                todayInviteAmount: 0, // 今日推广收益金额
                todayInvites: 0, // 今日推广人数
                totalInvites: 0, // 总推广人数
                totalInviteAmount: 0, // 总推广收益
                pageNum: 1,
                pageSize: 10,
                total: 0,
                inviteList: []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.inviteList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                let type = this.$route.query.t;
                this.loading = true;
                let req = {
                    pageNumber: this.pageNum,
                    pageSize: this.pageSize,
                }
                let ret = await this.$Api(type == 'game' ? queryGameInvite : queryAppInvite, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list ? ret.data.list : [];
                    this.todayInviteAmount = ret.data.todayInviteAmount;
                    this.todayInvites = ret.data.todayInvites;
                    this.totalInvites = ret.data.totalInvites;
                    this.totalInviteAmount = ret.data.totalInviteAmount;
                    this.inviteList = this.inviteList.concat(list);

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
        },
    }
</script>

<style lang='scss' scoped>
    .details {
        .navbar {
            padding: 0 16px;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $vermillion;
            color: $white1;
            font-size: 20px;

            .rightItem {
                width: 18px;
            }
        }

        .promoteInfo {
            // margin: 20px 0 17px;
            height: 82px;
            font-size: 14px;

            .promoteInfo_title {
                margin-bottom: 4px;
            }

            .promoteInfo_num {
                font-size: 16px;

                .goldSvg {
                    margin-right: 2.4px;
                }
            }
        }

        .promote_logo {
            height: 101px;

            // margin: 17px 0 11px;
            .promote_logo_svg {
                width: 50px;
                height: 50px;
            }

            .promote_logo_title {
                font-size: 14px;
                margin-top: 3px;
            }
        }

        .splitLine {
            height: 2px;
            background-image: linear-gradient(90deg, $white1 0%, $whiteFour 50%, $white1 100%);
        }

        .detailList_title {
            margin: 19px 16px;
            font-size: 18px;
        }

        .listBox {
            height: calc(100vh - 232px);
            // padding-top: 10px;
            .incomeInfo {
                margin: 0 16px 10px;
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: $silver;

                .title {
                    font-size: 13px;
                    font-weight: 600;
                }

                .amountBox {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 4px;

                    .type {
                        font-size: 13px;
                    }

                    .amount {
                        font-size: 15px;
                        color: $vermillion;
                    }
                }

                .timer {
                    font-size: 12px;
                    color: $warmGrey;
                    margin: 4px 0 10px;
                }
            }
        }


    }

    .mt10 {
        margin-top: 10px;
    }
</style>